<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of all Screens
        <div class="card-header-actions"></div>
      </b-card-header>
      <b-card-body>
        <v-client-table
          :columns="columns"
          :data="data"
          :options="options"
          theme="bootstrap4"
          id="dataTable"
        >
          <div slot="Edit" slot-scope="props">
            <i
              class="fa fa-edit"
              @click="editScreen(props.row.ScreenID, props.row.CinemaID)"
            ></i>
          </div>
          <div slot="ViewSeats" slot-scope="props">
            <a
              href
              @click.prevent="
                onShowSeatLayout(props.row, props.index, $event.target)
              "
              class="icon-eye"
            ></a>
          </div>

          <div slot="ScreenIsActive" slot-scope="props">
            <c-switch
              class="mx-1"
              color="primary"
              v-model="props.row.ScreenIsActive"
              variant="3d"
              label
              v-bind="labelIcon"
              @change.native="changeStatus(props.row.ScreenID)"
            />
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>

    <!-- Info modal  @hide="resetModal" -->
    <b-modal
      size="xl"
      id="ScreenSeatLayoutModal-Info"
      ref="ScreenSeatLayoutModalInfo"
      :title="seatLayoutTitle + ' Seat Layout'"
      ok-only
    >
      <div class="container">
        <div class="seatSectHdr">
          <div id="divSeats" class="seatIcnHdr">
            <!-- <h1>here we are</h1> -->
            <table border="0">
              <tbody
                v-for="(seatLayout, index) in seatsObject.SeatLayouts"
                :key="index"
              >
                <tr v-if="index == 0">
                  <td colspan="50">
                    <div class="seat-catg screen screen-class">SCREEN</div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <!-- seatLayout.Seats[0][0].SeatClassID -->
                <tr
                  v-if="
                    seatLayout.Seats.length > 0 &&
                    seatLayout.Seats[0].length > 0 &&
                    seatLayout.Seats[0][0].hasOwnProperty('SeatClassID')
                  "
                >
                  <td colspan="50">
                    <div
                      class="seat-catg screen-class"
                      style="
                        height: 21px;
                        margin-top: 10px;
                        text-transform: uppercase;
                      "
                      >{{
                        seatLayout.SeatClass.filter((x) => {
                          return (
                            seatLayout.Seats[0][0].SeatClassID == x.SeatClassID
                          );
                        })[0].Label
                      }}&nbsp; CLASS</div
                    >
                  </td>
                </tr>
                <tr
                  v-for="(seatArray, _index) in seatLayout.Seats"
                  :key="_index"
                >
                  <td v-for="(seat, seatIndex) in seatArray" :key="seatIndex">
                    <div v-if="seat.Status == 1" class="seat-area seat">
                      <input
                        type="checkbox"
                        :id="seat.SeatName"
                        :name="seat.SeatName"
                      />
                      <label :for="seat.SeatName" class="seat-x">{{
                        seat.SeatName
                      }}</label>
                    </div>
                    <div v-else-if="seat.Status == 2" class="seat-area seat">
                      <input
                        disabled
                        type="checkbox"
                        :id="seat.SeatName"
                        :name="seat.SeatName"
                      />
                      <label :for="seat.SeatName" class="seat-x">{{
                        seat.SeatName
                      }}</label>
                    </div>
                    <div v-else-if="seat.Status == 3" class="seat-area seat">
                      <input
                        type="checkbox"
                        :id="seat.SeatName"
                        :name="seat.SeatName"
                      />
                      <label :for="seat.SeatName" class="seat-x">
                        <i class="fa fa-wheelchair"></i>
                        {{ seat.SeatName }}
                      </label>
                    </div>
                    <div v-else class="seat-area seat">
                      <label class="seat-x border-0">&nbsp;</label>
                    </div>

                    <!-- <div><i class="fa fa-wheelchair"></i></div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import ScreenService from "@/services/ScreenService";
import MasterService from "@/services/MasterService";

export default {
  name: "AllScreens",
  components: {
    Event,
    cSwitch,
  },
  data: function () {
    return {
      seatLayoutTitle: "",
      seatsObject: {
        SeatLayouts: [],
      },

      columns: [
        "ScreenName",
        "CinemaName",
        "ScreenIsActive",
        "Edit",
        "ViewSeats",
      ],
      data: [],
      options: {
        headings: {
          name: "Screen Name",
          Edit: "Edit",
          ScreenIsActive: "Status",
        },
        sortable: ["CinemaName", "ScreenName"],
        filterable: ["CinemaName", "ScreenName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  mounted() {
    this.getScreens(0);
  },
  methods: {
    getScreens: function (CinemaID) {
      this.data = [];
      let payload = {
        CinemaID,
      };
      ScreenService.getScreens()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.ScreenList.length > 0) {
            this.data = data.ScreenList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editScreen: function (ScreenID, CinemaID) {
      this.$router.push({
        path: "/org-management/screens/add-screen-seatlayout",
        query: { ScreenID, CinemaID },
      });
    },
    changeStatus: function (ID) {
      let payload = {
        TableName: "Screen",
        ID,
      };
      MasterService.activateOrDeactivate(payload)
        .then((response) => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onShowSeatLayout: function (item, index, button) {
      console.log("view seat layout ", item, index);
      this.seatLayoutTitle = item.ScreenName || "";
      ScreenService.getScreenSeatLayoutData({ ScreenID: item.ScreenID })
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            if (
              data.SeatLayouts &&
              data.SeatLayouts.length > 0 &&
              data.SeatLayouts[0].hasOwnProperty("Seats") &&
              data.SeatLayouts[0].Seats &&
              data.SeatLayouts[0].Seats.length > 0
            ) {
              this.seatsObject = data;
              let seatArray = data.SeatLayouts;
              if (seatArray[0].Seats) {
                seatArray.map((x) => {
                  x.Seats.map((y) => {
                    y.SelectStatus = false;
                    y.SelectStatusFlag = false;
                    return y;
                  });
                  x.Seats = _.chunk(x.Seats, data.Column);
                  x.SeatClass = data.SeatTypes;
                  return x;
                });
              }
              this.seatsObject["SeatLayouts"] = seatArray;
              // this.$root.$emit(
              //   "bv::show::modal",
              //   "ScreenSeatLayoutModal-Info",
              //   button
              // );
              this.$refs["ScreenSeatLayoutModalInfo"].show();
              console.log("this.seatsObject", this.seatsObject["SeatLayouts"]);
            } else {
              this.showMessageSnackbar(
                data.Message ||
                  `There is no Seat Layout for ${this.seatLayoutTitle} , Please Insert the Seat Layout`
              );
            }
          } else {
            this.showMessageSnackbar(
              data.Message ||
                "We are unable to get the Seat Layout, Please Try after some time"
            );
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style scoped>
/*seat layout*/
/* width */
.seat-layout-box ::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
/* Track */
.seat-layout-box ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
.seat-layout-box ::-webkit-scrollbar-thumb {
  background: #7b7171;
  border-radius: 10px;
}
/* Handle on hover */
.seat-layout-box ::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, rgb(44, 2, 73) 15%, rgb(224, 0, 124));
}
.movie-sl-list .movie-format {
  color: #000;
  font-size: 10px;
  border-radius: 0.3rem;
  background-color: #fff;
  border: 1px solid #ccc;
  font-weight: 500;
  padding: 2px 5px;
}
.sl-list-box {
  color: #ddd !important;
}
.seatHdr.movieWrapHdr {
  top: -370px;
}
.movieWrapHdr {
  position: relative;
  top: -210px;
  z-index: 5;
  width: 1140px;
  margin: 0 auto;
}
.seat-layout-box {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.seatSectHdr {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}
.colA .head {
  font-size: 1rem;
  font-weight: 500;
}
.colA p {
  margin: 0;
}
.head {
  width: 100%;
}
.head .fa-arrow-left {
  color: #000;
  padding-right: 10px;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.seatIcnHdr {
  margin: 0 auto;
  width: 90%;
  height: 300px;
  text-align: center;
  font-size: 0.7rem;
  overflow: auto;
}
.seatIcnHdr table {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
}
.screen {
  color: #e5007d !important;
  text-align: center !important;
}
.seat-catg {
  line-height: 30px;
  text-align: left;
  font-size: 0.8rem;
  color: #d4d4d4;
}
.screen:before {
  border: 2px solid;
  content: "";
  display: block;
  width: 70%;
  margin: 0 auto;
  border-radius: 10px;
}
.seatHd {
  width: 25px;
  line-height: 25px;
  color: #999;
}
.seat {
  /* width: 28px; */
  width: 35px;
  line-height: 21px;
  margin: 5px 2px;
  cursor: pointer;
}
.seat-area input[type="checkbox"] {
  height: 0;
  width: 0;
  display: none;
}
.seat-area label {
  display: block;
  text-align: center;
  border-radius: 5px 5px 0 0;
  text-decoration: none;
  border: 1px solid #d5d5d5;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: #999;
  font-weight: bold;
}
.seat-area input[type="checkbox"]:checked + label {
  background-color: #e5007d !important;
  color: #fff;
}
.seat-area input[type="checkbox"]:disabled + label {
  background-color: #ccc;
  color: #fff;
}
/*seat footer section S.A.S*/
.seatselDtl {
  background-color: #fff;
  border-top: 1px solid #ccc;
}
.seats {
  color: #b9b9b9;
  font-weight: bold;
  font-size: 0.7rem;
  text-align: center;
  text-transform: uppercase;
}
.seats div {
  margin: 0 20px;
  display: inline-block;
}
.seats span {
  top: 5px;
  width: 25px;
  height: 20px;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  border: 1px solid #999;
  border-radius: 8px 8px 0 0;
}
.seat a.block,
.seats .block {
  color: #999;
  color: #fff;
  cursor: text;
  background-color: #ccc;
}
.seat a.slectd,
.seats .slectd {
  background-color: #e5007d;
  color: #ffff;
}
.dropdown-seat {
  position: absolute !important;
  top: 20px;
  right: 2%;
}
.sum .fa-location-arrow {
  color: #000;
}
.movie-dtail-modal button.close {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  position: absolute;
  right: -26px;
  top: -30px;
  z-index: 2000;
  opacity: 1;
  color: #ddd;
}
.movie-dtail-modal button.close:hover {
  opacity: 0.5 !important;
  color: #ddd !important;
}
.movie-dtail-modal .close {
  float: right;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  cursor: pointer;
}
.modal-bg {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 111;
  background: rgba(0, 0, 0, 0.5);
}
/*SELECT NUMBER OF SEATS CSS*/
.numBox {
  height: 50px;
  width: 50px;
  font-size: 1.9rem;
  cursor: default;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  transition: all 200ms ease;
  -o-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
}
.numBox:hover {
  background: #e5007d;
  color: #fff;
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.selected {
  color: #fff;
  cursor: pointer;
  background: #e5007d;
  transform: scale(1.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.price-list {
}
.city-link a:hover {
  color: grey;
  text-decoration: none;
}
.screen-class {
  color: #e5007d;
  text-align: center;
  font-weight: 600;
}
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}
</style>
